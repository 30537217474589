import React, { useEffect, useState } from "react";
import { list, getUrl } from "aws-amplify/storage";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PolicyIcon from "@mui/icons-material/Policy";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import policy from "../Images/policy.png"
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider,createTheme } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

export default function PolicyPage() {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black
      },
      text: {
        primary: "#000000", // Black
      },
    },
  });
  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: "order/InternalPol",
      });

      const modifiedFiles = result.items.map((file) => {
        let fileNameWithoutPrefix = file.key.replace("order/InternalPol/", "");
        let additionalInfo = "";
        const indexOfWef = fileNameWithoutPrefix.indexOf(
          "(w.e.f."
        );
        if (indexOfWef !== -1) {
          additionalInfo = fileNameWithoutPrefix.substring(
            indexOfWef,
            fileNameWithoutPrefix.length-4
          );
          fileNameWithoutPrefix = fileNameWithoutPrefix.substring(
            0,
            indexOfWef
          );
        }
        return {
          ...file,
          name: fileNameWithoutPrefix,
          additionalInfo,
        };
      });

      sortFilesByLastModified(modifiedFiles); 
      setFiles(modifiedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const sortFilesByLastModified = (files) => {
    files.sort((a, b) => {
      if (b.lastModified != null && a.lastModified != null) {
        return b.lastModified.getTime() - a.lastModified.getTime();
      } else {
        return 0;
      }
    });
  };

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg=async()=>
    {
      navigate("/admin_aries_app/Msg")
    }   

    const handleHelp = () => {
      const email = "ashish@aries.res.in";
      const subject = "Need help With Aries Connect"; 
      const body = ""; 
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    };
     
const handleLogout = async () => {
try {
  await signOut();
  navigate("/admin_aries_app/");
} catch (e) {
  console.log(e);
}
};



  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };


  const handleDownload = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="md">
    {/* <Grid container justifyContent="center" style={{ marginTop: "20px" }}> */}

{/* 
      <ArrowBackIcon
        fontSize="large"
        onClick={() => window.history.back()}
        sx={{ position: 'absolute', top: '20px', left: '10px', cursor: 'pointer', color: theme.palette.primary.main }}
      />
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={isSmallScreen ? 12 : 11}>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', fontStyle: 'italic', fontFamily: 'Roboto, sans-serif', color: theme.palette.primary.main }}
          >
            <PolicyIcon sx={{ fontSize: 'medium', mb: 0, mt: 0 }} /> Aries Policies
          </Typography>
        </Grid>
      </Grid> */}

      <Box

          
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  backgroundColor: '#a5c7ed',

  borderRadius: 4,
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
          sx={{ marginBottom: '0rem' }}
        >
          Back
        </Button>
<img
  src={policy} 
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Aries Policies
</Typography>
</Box>
<Box sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }}>

      <List dense>
        {files.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              mb: 1,
              backgroundColor: 'white',
            }}
            secondaryAction={
              <IconButton onClick={() => handleDownload(file.key)}>
                <CloudDownloadIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  sx={{
                    // fontWeight: 'bold',
                    overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    // whiteSpace: 'nowrap',
                    width: 'calc(100% - 40px)', // Adjust width considering icon button width
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  {file.name}
                </Typography>
              }
              secondary={file.additionalInfo}
            />
          </ListItem>
        ))}
      </List>
    {/* </Grid> */}
    </Box>
     </Container>
     <MobileBottomNavBar

     handleLogout={handleLogout}
     handleUserInfo={handleUserInfo}
     handleMsg={handleMsg}
     handleHelp={handleHelp}
     handleHome={handleHome}
   
   />
 </ThemeProvider>
  );
};