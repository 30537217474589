import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { confirmResetPassword } from 'aws-amplify/auth';

// Define the styles using styled utility directly
const styles = {
  container: {
    marginTop: '4rem',
  },
  textField: {
    marginBottom: '2rem',
  },
};

const ConfirmPassword = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleConfirmPassword = async () => {
   
      if (!location.state || !location.state.username) {
        console.error("location.state.email is undefined");
        return;
      }
  
      //RECIEVING EMAIL AS PROP FROM FORGET PASSWORD
      const username = location.state.username;
      
      try {
        await handleConfirmResetPassword({
          username: username,
          confirmationCode: password, // Assuming OTP is entered as password
          newPassword: confirmPassword
        });
        console.log('Password confirmed successfully!');
        navigate('/admin_aries_app/'); // Redirect to home or login page after confirmation
      } catch (error) {
        console.error("Error confirming password:", error);
      }
    
  };

  const handleConfirmResetPassword = async ({
    username,
    confirmationCode,
    newPassword
  }) => {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
      throw error;
    }
  }

  return (
    <Container maxWidth="sm" style={styles.container}>
      <Typography variant="h4" gutterBottom>
        Confirm Password
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            style={styles.textField}
            label="OTP"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={styles.textField}
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={error !== ''}
            helperText={error}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirmPassword}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConfirmPassword;
