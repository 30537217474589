import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FAQPage = () => {
  // State to manage the visibility of answers
  const [expanded, setExpanded] = useState([]);

  // Function to toggle visibility of answer
  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Frequently Asked Questions</h1>
      {/* Mapping over questions and answers */}
      <div style={styles.questionsContainer}>
        {questions.map((qa, index) => (
          <div key={index} style={styles.item}>
            {/* Question header with onClick to toggle answer visibility */}
            <div
              style={{ ...styles.question, cursor: 'pointer' }}
              onClick={() => toggleExpand(index)}
            >
              {/* <span style={{ marginRight: '10px' }}>
                {expanded[index] ? '▼' : '►'}
              </span> */}
              {qa.question}
              <KeyboardArrowDownIcon style={{ marginLeft: '5px' }} />

            </div>
            {/* Answer with conditional rendering based on expanded state */}
            {expanded[index] && <p style={styles.answer}>{qa.answer}</p>}
            {/* Separator line */}
            <div style={styles.separator}></div>
          </div>
        ))}
      </div>
    </div>
  );
}

// Styles
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    fontSize: '28px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  questionsContainer: {
    borderTop: '1px solid #ccc',
  },
  item: {
    marginBottom: '20px',
  },
  question: {
    fontSize: '19px',
    fontWeight: 'bold',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  answer: {
    fontSize: '16px',
    lineHeight: '1.6',
  },
  separator: {
    borderTop: '1px solid #ccc',
    margin: '10px 0',
  },
};

// Questions and answers data
const questions = [
  {
    question: 'What is AriesConnect?',
    answer: '"AriesConnect is an application designed for employees of the Aryabhata Research Institute of Observational Sciences. It serves as a comprehensive platform allowing employees to access their salary slips, office correspondence, government orders, NPS updates, telephone directory, holidays, and ERP functionalities, all within a single app.',
  },
  {
    question: 'How do I sign up for AriesConnect?',
    answer: 'To sign up for AriesConnect, simply download the mobile app from the aries.res.in. Once installed, open the app and follow the on-screen instructions to create your account. You will be prompted to provide some basic information and choose a username and password.',
  },
  {
    question: 'I forgot my password. What should I do?',
    answer: 'If you forgot your password, don\'t worry! On the login screen, there should be an option to reset your password. Click on the "Forgot Password" link, and follow the instructions to reset your password. You may be asked to provide your email address or phone number associated with your account for verification purposes.',
  },
  {
    question: 'Is my personal information secure on AriesConnect?',
    answer: 'Yes, we take the privacy and security of our users\' information very seriously. We use industry-standard encryption protocols to protect your data and have strict privacy policies in place to ensure that your personal information is not shared with third parties without your consent.',
  },
  {
    question: 'How can I contact support if I have further questions or issues?',
    answer: 'If you have any further questions, encounter technical issues, or need assistance with anything related to AriesConnect, please don\'t hesitate to contact our support team. You can usually find a "Help Us Section"  option within the app, where you can submit your queries or issues. Our support team will respond to you as soon as possible and assist you with your concerns.',
  },
];

export default FAQPage;
