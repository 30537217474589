  import React, { useEffect, useState } from "react";
  import Container from "@mui/material/Container";
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import CssBaseline from "@mui/material/CssBaseline";
  import Box from "@mui/material/Box";
  import Stack from "@mui/material/Stack";
  import Typography from "@mui/material/Typography";
  import Button from "@mui/material/Button";
  import { useNavigate } from "react-router-dom";
  import ImageViewer from "./ImageViewer";
  import { list, getUrl } from "aws-amplify/storage";
  import { generateClient } from "aws-amplify/api";
  // import { listUSERS, getUSER } from "../graphql/queries";
  import { useLocation } from "react-router-dom";
  import { getCurrentUser } from "aws-amplify/auth";
  import { signOut } from "aws-amplify/auth";
  import LogoutIcon from '@mui/icons-material/Logout';
  import HelpIcon from '@mui/icons-material/Help';
  import { DataStore } from 'aws-amplify/datastore';
  import { USER } from '../models';
  // import { DataStore } from "aws-amplify/datastore";
  import { Notify } from "../models";
  // IMPORT PAGES
  import contact from'../Images/contacts.png'
  import erp from "../Images/erp.png";
  import AccountCircleIcon from '@mui/icons-material/AccountCircle';
  import gallery from "../Images/gallery.png";
  import govt from "../Images/govt.png";
  import holiday from "../Images/holiday.png";
  import policy from "../Images/policy.png";
  import nps from "../Images/nps.png";
  import ariesname from "../Images/aries_logo_auto_6_3_1.png"
  import notice from "../Images/notice.png";
  import forms from "../Images/forms.png";
  import officeorder from "../Images/officeorder.png";
  import pay from "../Images/salary1.png";
  import tel from "../Images/telephone.png";
  import life from "../Images/life.png";
  import ariesLogo from "../Images/aries_logo.png";
  import MobileBottomNavBar from "./mobilenavbar";
  import profile from "../Images/5739115-200.png";
  import logo from "../Images/Untitled.png";
  import admin from "../Images/Untitled.png";
  import Dialog from "@mui/material/Dialog";
  import DialogTitle from "@mui/material/DialogTitle";
  import DialogContent from "@mui/material/DialogContent";
  import DialogActions from "@mui/material/DialogActions";
  
  import YouTubeIcon from '@mui/icons-material/YouTube';
  import FacebookIcon from '@mui/icons-material/Facebook';
  import TwitterIcon from '@mui/icons-material/Twitter';
  import Follow from "./follow"
  // import logoutImage from "../Images/logout.png";s
  // import backgroundImage01 from "../Images/backgroundImage03.jpg";
  // import userImage from '../Images/user.png';
  import {  MailOutline as MessageIcon } from "@mui/icons-material";
  const defaultTheme = createTheme();

  const Home = () => {
    const navigate = useNavigate();
   const [iframeSrc, setIframeSrc] = useState("");
    const [ Loading,setLoading] = useState(true);
    const [hasUnseenNotification, setHasUnseenNotification] = useState(false);
    const [ setHasUnreadNotification] = useState(false);
    const [UserName, setUserName] = useState('');
    const [Data,setData]=useState([]);
    // const [openDialog, setOpenDialog] = useState(faslse);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogTel, setOpenDialogTel] = useState(false);
  const [showImage, setShowImage] = useState(false);
const [holidayClicked, setHolidayClicked] = useState(false);

    // const location = useLocation();
    let userDetails = null;

    useEffect(() => {
      localStorage.removeItem('IMG_URL');
      localStorage.removeItem('tel_url');
      // const usernamea = localStorage.getItem('data');
      // console.log(usernamea)
      // setUserName(usernamea)
      userDetails = fetchData();
      checkNotificationStatus();
      checkNotificationReadStatus();
    }, []);
    const fetchData = async () => {
      const client = generateClient();

      try {

        const { username, userId, signInDetails } = await getCurrentUser();

        const models = await DataStore.query(USER);

        for (let i = 0; i < models.length; i++) {
          


          const item = models[i];
          if (item.uid === userId) {
            // if(!UserName)
              // {
                setUserName(item.name)
                setData(item);
              // }
            return item;

          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    
    const handleButtonClickofContact = () => {
 
      navigate("/admin_aries_app/Contact", );
    };

    const handleButtonClickoferp = () => {
      const url = "http://erp.aries.res.in:7073/web/login";
      window.open(url, "_blank");
    };


    const styles = {
      header: {
      // display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        height: '50%',
      padding: '10px 10px',
        backgroundColor: '#a5c7ed', // Light background color
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for better UI
      },
      profileIcon: {
        width: '40px',
        height: '40px',
        marginLeft: '190px',
        cursor: 'pointer',
      },
      welcomeText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
      },
      welcome: {
        marginRight: '5px',
        fontWeight: 'bold',
        display: 'block', // Ensures "Welcome" is on its own line
      },
      userName: {
        marginRight: '10px', // Add some space between the name and the profile image
        fontWeight: 'bold',
        display: 'block',
        whiteSpace: 'nowrap', // Ensures username is on its own line
      },
      profileImage: {
        width: '40px',
        height: '40px',
        position: 'realtive',
        // marginTop: '-100px',
        marginLeft: '110px',
        borderRadius: '50%', // Make the image circular
        cursor: 'pointer',
      },
    };
    


    const checkNotificationStatus = async () => {
      try {
        const { userId } = await getCurrentUser();
        const notifications = await DataStore.query(Notify);

        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].username === userId && !notifications[i].seen) {
            setHasUnseenNotification(true);
            // console.log(hasUnseenNotification);
            return;
          }
        }
      } catch (error) {
        console.error("Error checking notification status:", error);
      }
    };

    const checkNotificationReadStatus = async () => {
      try {
        const { userId } = await getCurrentUser();
        const notifications = await DataStore.query(Notify );
        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].username === userId && !notifications[i].read) {
            setHasUnreadNotification(true);
            // console.log(hasUnreadNotification )
          return;
        }
        }
      } catch (error) {
        console.error("Error checking notification read status:", error);
      }
    };


    const [telurl, settelurl] = useState('');


    const handleButtonClickofTelView = async () => {
      try {
        const filename = "downloads/Telephone_Directory.jpg";
        try {
          const getUrlResult = await getUrl({
            key: filename,
            options: {
              accessLevel: "guest",
            },
          });
        
         localStorage.setItem('tel_url', getUrlResult.url.href);
      
          navigate("/admin_aries_app/TelImg", );
        } catch (error) {
          console.error("Error getting signed URL:", error);
        }
      } catch (error) {
        console.log(error);
      }
    };


    const handleButtonClickoftel = async () => {
      try {
      
        const filename = "downloads/Telephone_Directory.pdf";
        try {
          const getUrlResult = await getUrl({
            key: filename,
            options: {
              accessLevel: "guest",
            },
          });
          // <iframe src={getUrlResult.url.href} width="100%" height="600px"></iframe>
          window.open(getUrlResult.url.href);
        } catch (error) {
          console.error("Error getting signed URL:", error);
        }
      } catch (error) {
        console.log(error);
      }
    };


    const [dropdownVisible, setDropdownVisible] = useState(false);

      const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
      };

      const handleOpenDialog = () => {
       
        setOpenDialog(true);
      };
      
      const handleCloseDialog = () => {
        setOpenDialog(false);
        // setimgurl('')
      };

//
const [dropdownVisibleTel, setDropdownVisibleTel] = useState(false);

const toggleDropdownTel = () => {
  setDropdownVisible(!dropdownVisible);
};

const handleOpenDialogTel = () => {
 
  setOpenDialogTel(true);
};

const handleCloseDialogTel = () => {
  // setOpenDialog(false);
  setOpenDialogTel(false);
  

  // setimgurl('')
};
      
      // const [imgurl, setimgurl] = useState('');


      const handleButtonClickofHolidayView = async () => {
        try {
          const filename = "downloads/Calendar_page.jpg";
          try {
            const getUrlResult = await getUrl({
              key: filename,
              options: {
                accessLevel: "guest",
              },
            });
          
           localStorage.setItem('IMG_URL', getUrlResult.url.href);
        
            navigate("/admin_aries_app/Img", );
          } catch (error) {
            console.error("Error getting signed URL:", error);
          }
        } catch (error) {
          console.log(error);
        }
      };


    const handleButtonClickofHoliday = async () => {
      try {
    
      
        const filename = "downloads/Calendar.pdf"
        try {
          const getUrlResult = await getUrl({
            key: filename,
            options: {
              accessLevel: "guest",
            },
         }); 
         console.log("hiii",getUrlResult)
        
          window.open(getUrlResult.url.href);
        } catch (error) {
          console.error("Error getting signed URL:", error);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleButtonClickofOrder = () => {
      navigate("/admin_aries_app/Order");
    };

    const handleButtonClickofForms = () => {
      navigate("/admin_aries_app/FormsPage");
    };

    const handleButtonClickofOfficeOrder = () => {
      navigate("/admin_aries_app/OfficeOrders");
    };

    const handleButtonClickofPolicy = () => {
      navigate("/admin_aries_app/PolicyPage");
    };

    const handleButtonClickofNps = () => {
      navigate("/admin_aries_app/NpsPage");
    };

    const handleButtonClickofNotice = () => {
      navigate("/admin_aries_app/NoticePage");
    };

    const handlegallery = () => {
      navigate("/admin_aries_app/Gallery");
    };

    const handleUserInfo = () => {
      navigate('/admin_aries_app/UserInfo')
    };

    const handleButtonClickofLifeCertificate = async () => {
      try {
        const { userId } = await getCurrentUser();
        const client = generateClient();
        
        const allUSERS = await DataStore.query(USER);
  

        for (let i = 0; i < allUSERS.length; i++) {
          const detailSend = allUSERS[i];

          if (detailSend.uid === userId) {
            
            navigate("/admin_aries_app/LifeCertificate", {
              state: {detailSend},
            });
            return detailSend;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const handleMsg=async()=>
      {
        navigate("/admin_aries_app/Msg")
      }

    const handleButtonClickofPaySlip = async () => {
      try {
        const { userId } = await getCurrentUser();
        const client = generateClient();
        const allUSERS = await DataStore.query(USER);
        // const allUSERS 
        const items = allUSERS.items;

        for (let i = 0; i < allUSERS.length; i++) {
          const detailSend = allUSERS[i];

          if (detailSend.uid === userId) {
            navigate("/admin_aries_app/PaySlip", { state: { detailSend } });
            return detailSend;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Logout handler
    const handleLogout = async () => {
      try {
        await signOut();    localStorage.removeItem('authToken');
        localStorage.removeItem('authTokenExpiration');
        navigate("/admin_aries_app/login");
      } catch (e) {
        console.log(e);
      }
    };
    const handleHelp = () => {
      const email = "syserp@aries.res.in";
      const subject = "Need help With Aries Connect"; 
      const body = ""; 
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    };
    
  const handelOKfrompopup=async ()=>{
    const models = await DataStore.query(Notify);
    const { userId } = await getCurrentUser();

  for(var i=0;i<models.length;i++){

  if(models[i].username==userId&&models){
    // console.log("here")
    try{
  console.log(models[i])
      await DataStore.save(Notify.copyOf(models[i], item => {
        
        item.seen=true;
      }));
    }
    catch(e){
  console.log(e);
    }
    setHasUnseenNotification(false); 

    
  }
  }


  }

    return (
      <>

     


        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              marginBottom:'90px',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CssBaseline />
            <Container component="main" maxWidth="md" >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >

        
                {/* Aries Logo */}
                <img
                  src={ariesLogo}
                  alt="Aries Logo"
                  style={{
                    width: "30%",
                    marginTop: "15px",
                    marginLeft: "10px", // Adjust the left margin to shift it to the left
                  }}
                />



  <img
                  src={ariesname}
                  alt="Aries"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: '10px',

                    marginLeft: "10px", // Adjust the left margin to shift it to the left
                  }}
                />

  {hasUnseenNotification && (

          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999, // Ensure the overlay is on top of other elements
              backdropFilter: 'blur(5px)', // Blur effect
            }}
          >
            <div
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                padding: '15px',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                textAlign: 'center',
                width: '70%', // Full width for mobile devices
                maxWidth: '400px', // Max width to ensure it's not too large on bigger screens
              }}
            >
              <p>You have a new notification.</p>
              <button
                style={{
                  backgroundColor: '#fff',
                  color: '#007bff',
                  border: 'none',
                  padding: '8px 15px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={handelOKfrompopup}
              >
                OK
              </button>
            </div>
          </div>)}

              

  {/* 
  <div style={{ display: "flex", alignItems: "center" }}>
    <AccountCircleIcon
      onClick={handleUserInfo}
      style={{ width: "40px", height: "40px", marginLeft: "230px", marginTop: "0px" }}
    />
  <MessageIcon
          onClick={ handleMsg}
          style={{ width: "40px", height: "40px", marginLeft: "10px", marginTop: "0px" }}
        />
    <LogoutIcon
      onClick={handleLogout}
      style={{ width: "40px", height: "40px", marginLeft: "15px", marginTop: "0px" }}
    />
    <HelpIcon onClick={toggleDropdown}
      style={{ width: "40px", height: "40px", marginLeft: "10px", marginTop: "0px" }}
    />



  </div> */}




  <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.welcomeText}>
          <span style={styles.welcome}>Welcome,</span>
          
          <span style={styles.userName}>{UserName}</span>
          <img
            src={profile} // Replace with the path to your image
            alt="Profile"
            style={styles.profileImage}
            onClick={handleUserInfo}
          />
        </div>
      </div>
    </div>



  <div style={{ position: "relative",  display: "inline-block" }}>
        {dropdownVisible && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 60,
              width: 120,
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li
              onClick={handleHelp}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                }}
              
              >
                Asking Help?
              </li>

              <li
                style={{
                  padding: "10px",
                  cursor: "pointer",
                }}
              
              >
                User Manual
              </li>




              {/* Add more options here */}
            </ul>
          </div>
        )}
      </div>
    






  <Typography
    component="h1"
    variant="h3"
    sx={{
      textAlign: "center",
      fontWeight: "bold", // You can adjust the font weight if needed
      mt: 2,
      mb: 2,
      fontFamily: 'Roboto, sans-serif', // Use Roboto font with fallback to sans-serif
      fontSize: "3rem", // Adjust the font size as needed
      "@media (max-width:600px)": {
        fontSize: "1.4rem", // Adjust the font size for smaller screens
      },
    }}
  >
    {/* Aries Connect */}
  </Typography>


                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {/* Buttons */}
                  <ButtonComponent
                    handleClick={handleButtonClickofContact}
                    image={contact}
                    // text="Contact"
                    text={<span style={{ textTransform: 'capitalize' }}>Contact</span>}
                  />
                  
                      {Data.active && (
  <ButtonComponent
  handleClick={handleButtonClickoferp}
  image={erp}
  // text="ERP"
  text={<span style={{ textTransform: 'capitalize' }}>ERP</span>}
/>
)}
                  <ButtonComponent
                    handleClick={handlegallery}
                    image={gallery}
                    text={<span style={{ textTransform: 'capitalize' }}>Gallery</span>}
                    // text="Gallery"
                  />
             <ButtonComponent
  handleClick={handleButtonClickofOrder}
  image={govt}
  text={<span style={{ textTransform: 'capitalize' }}>Govt Order</span>}
/>


       {/* <div> */}
      
      <ButtonComponent
  handleClick={  handleOpenDialog}
  image={holiday
      }
      text={<span style={{ textTransform: 'capitalize' }}>Holiday</span>}
  // text="HOLIDAY"
/>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Are you sure you want to proceed with the holiday action?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleButtonClickofHolidayView} color="primary" autoFocus>
            View
          </Button>
          <Button onClick={handleButtonClickofHoliday} color="primary" autoFocus>
            Download
          </Button>
        </DialogActions>
      </Dialog>

 
  

                  <ButtonComponent
                    handleClick={handleButtonClickofPolicy}
                    image={policy}
                    text={<span style={{ textTransform: 'capitalize' }}>Policy</span>}
                    // text="POLICY"
                  />
                  <ButtonComponent
                    handleClick={handleButtonClickofNps}
                    image={nps}
                    text={<span style={{ textTransform: 'capitalize' }}>NPS Update</span>}
                    // text="NPS Update"
                  />
                  <ButtonComponent
                    handleClick={handleButtonClickofNotice}
                    image={notice}
                    text={<span style={{ textTransform: 'capitalize' }}>Notice</span>}
                    // text={"Notice"}
                  />
                  <ButtonComponent
                    handleClick={handleButtonClickofForms}
                    image={forms}
                    text={<span style={{ textTransform: 'capitalize' }}>Forms</span>}
                    // text="FORMS"
                  />
                  <ButtonComponent
                    handleClick={handleButtonClickofOfficeOrder}
                    image={officeorder}
                    text={<span style={{ textTransform: 'capitalize' }}>Office Orders</span>}
                    // text="Office Orders"
                  />
                  <ButtonComponent
                    handleClick={handleButtonClickofPaySlip}
                    image={pay}
                    text={<span style={{ textTransform: 'capitalize' }}>Pay Slip</span>}
                    // text="Pay Slip"
                  />

<ButtonComponent
  handleClick={  handleOpenDialogTel}
  image={tel}
  text={<span style={{ textTransform: 'capitalize' }}>Tel Directory</span>}
  // text="TEL Directory"
/>

      <Dialog open={openDialogTel} onClose={handleCloseDialogTel}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Are you sure you want to proceed with the Telephone action?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogTel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleButtonClickofTelView} color="primary" autoFocus>
            View
          </Button>
          <Button onClick={handleButtonClickoftel} color="primary" autoFocus>
            Download
          </Button>
        </DialogActions>
      </Dialog>
                
                
                {!Data.active && (
  <ButtonComponent
    handleClick={handleButtonClickofLifeCertificate}
    image={life}
    text={<span style={{ textTransform: 'capitalize' }}>Life Certificate</span>}
    // text="Life Certificate"
  />
)}

                </Box>
      
              </Box>

              {/* <Follow /> */}
        
            </Container>
            

            

            <MobileBottomNavBar

            
    
    handleLogout={handleLogout}
    handleUserInfo={handleUserInfo}
    handleMsg={handleMsg}
    handleHelp={handleHelp}
    handle={handleHelp}

  />
          </Box>
        </ThemeProvider>
      </>
    );
  };




  const ButtonComponent = ({ handleClick, image, text }) => {
    return (
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        style={{
          backgroundColor: "#a5c7ed",
          flexDirection: "column",
          alignItems: "center",
          margin: "10px",
          width: "20%", // Adjust the width to fit three buttons in a row
          maxWidth: "90px", 
          minWidth:"70px"
        }}
      >
        <Stack direction="column" spacing={1} alignItems="center">
          <img src={image} alt={text} style={{ width: "50px" }} />
          <Typography
            component="h1"
            variant="h6"
            style={{ color: "black", fontSize: "12px", fontWeight: "700" }}
          >
            {text}
          </Typography>
        </Stack>
      </Button>
    );
  };
  

  export default Home;
