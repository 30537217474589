import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { signUp } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";  
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";
import backgroundImage01 from "../Images/backgroundImage03.jpg"; // Import your image
import ariesLogo from "../Images/aries_logo.png"; // Corrected import name
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    phonenumber: "",
    password: "",
    cpassword: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, email, phonenumber } = formData;

    const username = email;

    var phone_number= "+91"+phonenumber;
    try { 
      const { userId } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            // email,
            phone_number,
          },
          autoSignIn: true,
        },
      });


      navigate("/admin_aries_app/ConfirmOtp", { state: { email, userId } });
    //  return { userId };
    } catch (error) {
      var name=error.toString();
      console.log(name)
      name=name.split(":")
      console.log("error signing up:", name[0]);
if(name[0]=='EmptySignUpUsername')
      toast.error(name[0], { style: toastStyle });
    if(name[0]=='UsernameExistsException')
      toast.error(name[0], { style: toastStyle });
    // if( name[0]==)
      // throw error;
    }
  };


   const toastStyle = {
    color: "white",
    backgroundColor: "red",
    fontWeight: "bold",
    fontSize: "16px",
  };

  const error = {}; 

  return (
    <>
    <Box sx={{ backgroundColor: "rgb(245,245,245 )", // Smokey white
      
      display: "flex",
     
    }}
        >
      <Grid container justifyContent="flex-end" marginTop={3} marginRight={2}>
    <Grid item>
      <Link href="/admin_aries_app/faq"  variant="body2" sx={{ color: "black" }}>
        Help?
      </Link>
    </Grid>
  </Grid>
        </Box>
    <Box
      sx={{
        backgroundColor: "rgb(245,245,245 )", // Smokey white
        minHeight: "100vh", 
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
        padding: "20px",
        textAlign: "center",
      }}
      >
    
    
        <Typography
          variant="h6"
          component="h1"
          align="left"
          sx={{ fontWeight: "900", fontSize:32}}
          >
         Create Account
        </Typography>



        <Typography
          variant="h6"
          component="h6"
          align="left"
          sx={{ fontWeight: "20", fontSize:12}}
          >
     Please fill in all the fields to conplete your registrstion
        </Typography>



        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 3,
            width: "100%",
            "& .MuiTextField-root": {
              marginBottom: "20px",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={formData.email}
                InputProps={{
                  startAdornment: <EmailIcon />,
                  sx: { fontWeight: "bold" },
                }}
                InputLabelProps={{ style: { fontWeight: "bold" } }}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="phonenumber"
                label="Phone/Mobile"
                name="phonenumber"
                onChange={handleChange}
                value={formData.phonenumber}
                InputProps={{
                  startAdornment: <PhoneIcon />,
                  sx: { fontWeight: "bold" },
                }}
                InputLabelProps={{ style: { fontWeight: "bold" } }}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Password"
                type="password"
                id="password"
                name="password"
                autoComplete="new-password"
                onChange={handleChange}
                value={formData.password}
                InputProps={{
                  startAdornment: <LockIcon />,
                  sx: { fontWeight: "bold" },
                }}
                InputLabelProps={{ style: { fontWeight: "bold" } }}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="cpassword"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                onChange={handleChange}
                value={formData.cpassword}
                InputProps={{
                  startAdornment: <LockIcon />,
                  sx: { fontWeight: "900" },
                }}
                InputLabelProps={{ style: { fontWeight: "bold" } }}
                />
            </Grid>
          </Grid>
          <Button
  type="submit"
  fullWidth
  variant="contained"
  sx={{ mt: 3, mb: 2, backgroundColor: "black" }} // Add color: "black" to make the button black
>
Register
</Button>

<Typography 
marginTop={3}
variant="h6"
component="h6"
align="center"
sx={{ fontWeight: "20",fontSize:12}}
>
     Already have login credentials?
   
      <Grid item >
        <Link href="/admin_aries_app/login"  variant="body2" sx={{ color: "black" }}>
        Login
        </Link>
      </Grid>
    
   


        </Typography>
            {/* </Box> */}
          <ToastContainer position="bottom-center" />
        </Box>
    
    </Box>
            </>
  );
}
