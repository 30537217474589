import React, { useEffect, useState } from 'react';
import { getCurrentUser } from "aws-amplify/auth";
import { DataStore } from 'aws-amplify/datastore';
import { useNavigate } from "react-router-dom";
import { Notify } from '../models';
import noNoti from "../Images/kindpng_2080049.png";
import { Snackbar, CircularProgress, Divider, ListItem, ListItemText } from '@mui/material';
import notificationIcon from '../Images/man.png';
import MobileBottomNavBar from "./mobilenavbar";
import { signOut } from "aws-amplify/auth";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import png from '../Images/pngn.png'

function Msg() {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchNotifications() {
            try {
                const currentUser = await getCurrentUser();
                const notifications = await DataStore.query(Notify);
                const userNotifications = notifications.filter(notification => notification.username === currentUser.username);
                // Sort the notifications by createdAt timestamp
                userNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setNotifications(userNotifications);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                setLoading(false);
            }
        }
        fetchNotifications();
    }, []);

    const markAsRead = async (notification) => {
        try {
            const aman = notification.id;
            navigate("/admin_aries_app/Msgdetails", { state: { aman } });

            await DataStore.save(Notify.copyOf(notification, item => {
                item.read = true;
                item.seen=true;
            }));
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const formatTimestamp = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };
    const backclick = () => {
        navigate("/admin_aries_app/Home");
      };

    const handleUserInfo = () => {
        navigate('/admin_aries_app/UserInfo')
      };

      const handleHome = () => {
        navigate('/admin_aries_app/Home')
      };

      const handleMsg=async()=>
        {
          navigate("/admin_aries_app/Msg")
        }   
         
   const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };
  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect"; 
    const body = ""; 
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };
    

    return (
        <>
        <Box

          
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  borderRadius: 4,
  backgroundColor: '#a5c7ed',

  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
         // Assuming this function handles navigation back
          sx={{ marginBottom: '0rem',  color: 'black',
        }}
        >
          Back
        </Button>
<img
  src={png} // Replace with your profile update logo path
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Notification
</Typography>
</Box>
            <Snackbar
            
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={loading}
                message="Loading..."
            />
            {!loading && notifications.length === 0 && (
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
           <img
             src={noNoti}
             alt="No Notification Found"
             style={{
               width: "60%",
             }}
           />
         </div>
         
         
            )}
            {!loading && notifications.length > 0 && (
                <div>
                    {notifications.map(notification => (
                        <div key={notification.id}>
                            <ListItem button onClick={() => markAsRead(notification)}>
                                <img src={notificationIcon} alt="Notification Icon" style={{ marginRight: 10, width:50}} />
                                <ListItemText
                                    primary={notification.message}
                                    secondary={`From Admin - ${formatTimestamp(notification.createdAt)}`}
                                />
                                {!notification.read && (
                                    <span style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '2px 6px',
                                        borderRadius: '4px',
                                        marginLeft: '10px'
                                    }}>
                                        New
                                    </span>
                                )}
                            </ListItem>
                            <Divider />
                        </div>
                    ))}
                     
                </div>
            )}
             <MobileBottomNavBar
  
  handleLogout={handleLogout}
  handleUserInfo={handleUserInfo}
  handleMsg={handleMsg}
  handleHelp={handleHelp}
  handleHome={handleHome}

/>
        </>
    );
}

export default Msg;
