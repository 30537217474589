import React from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '4px',
  backgroundColor: '#f0f0f0',
  borderRadius: '8px',
  textAlign: 'center',
});

const StyledIconButton = styled(IconButton)({
  '&:not(:last-child)': {
    marginRight: '8px',
  },
});

const Follow = () => {
  return (
    <StyledBox>
     <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
  Follow Us On:
</Typography>

      <Box display="flex" alignItems="center" ml={1}>
        <StyledIconButton
          href="https://twitter.com/ARIESNainital"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://static.vecteezy.com/system/resources/previews/027/395/710/non_2x/twitter-brand-new-logo-3-d-with-new-x-shaped-graphic-of-the-world-s-most-popular-social-media-free-png.png" alt="Twitter" style={{ width: '23px', height: '23px' }} />
        </StyledIconButton>
        <StyledIconButton
          href="https://www.facebook.com/aries.nainital263002/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png" alt="Facebook" style={{ width: '30px', height: '30px' }} />
        </StyledIconButton>
        <StyledIconButton
          href="https://www.youtube.com/c/AriesNainitalUttarakhand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://static.vecteezy.com/system/resources/thumbnails/023/986/480/small/youtube-logo-youtube-logo-transparent-youtube-icon-transparent-free-free-png.png" alt="YouTube" style={{ width: '30px', height: '30px' }} />
        </StyledIconButton>
      </Box>
    </StyledBox>
  );
};

export default Follow;
