import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArticleIcon from '@mui/icons-material/Article';
import { list, getUrl } from "aws-amplify/storage";
import forms from "../Images/forms.png";
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function FormsPage({ dense }) {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black
      },
      text: {
        primary: "#000000", // Black
      },
    },
  });
  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: "order/forms",
      });
     
      const modifiedFiles = result.items.map((file) => {
        let fileNameWithoutPrefix = file.key.replace("order/forms/", "");
        let additionalInfo = "";
        const indexOfWef = fileNameWithoutPrefix.indexOf(
          "-(w.e.f.-05.04.2024)"
        );
        if (indexOfWef !== -1) {
          additionalInfo = "(w.e.f.-05.04.2024)";
          fileNameWithoutPrefix = fileNameWithoutPrefix.substring(
            0,
            indexOfWef
          ); // Remove '(w.e.f.-05.04.2024)'
        }
        return { ...file, name: fileNameWithoutPrefix, additionalInfo };
      });

      sortFilesByLastModified(modifiedFiles); 
      setFiles(modifiedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const sortFilesByLastModified = (files) => {
    files.sort((a, b) => {
      if (b.lastModified != null && a.lastModified != null) {
        return new Date(b.lastModified) - new Date(a.lastModified);
      } else {
        return 0;
      }
    });
  };

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg = () => {
    navigate("/admin_aries_app/Msg")
  };

  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect"; 
    const body = ""; 
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };
     
  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      console.log("signed URL: ", getUrlResult);
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  return (
   
    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="md">
   
    <Box sx={{ display: 'flex', alignItems: 'center', boxShadow: 1, borderRadius: 4, padding: '1rem', backgroundColor: '#a5c7ed', marginTop: '1rem', marginBottom: '1rem', }}>
      <Button startIcon={<ArrowBackIcon />} onClick={backclick} sx={{ marginBottom: '0rem', color: 'black' }}>
        Back
      </Button>
      <img src={forms} alt="Profile Update Logo" style={{ width: '30px', marginRight: '0.5rem' }} />
      <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
        Forms
      </Typography>
    </Box>
    
    <Box sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }}>
      {files.length === 0 ? (
        <List>
          {[...Array(10)].map((_, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <Skeleton variant="circular" width={40} height={40} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Skeleton variant="text" width="80%" height={20} />}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          {files.map((file, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <PictureAsPdfIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={file.name} />
              <IconButton onClick={() => handleDownload(file.key)}>
                <CloudDownloadIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
    </Container>
    <MobileBottomNavBar
      handleLogout={handleLogout}
      handleUserInfo={handleUserInfo}
      handleMsg={handleMsg}
      handleHelp={handleHelp}
      handleHome={handleHome}
    />
  </ThemeProvider>

  );
}
