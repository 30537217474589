import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { list, getUrl } from "aws-amplify/storage";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataStore } from 'aws-amplify/datastore';
import { USER } from '../models';
import profile from '../Images/man.png'; // Import your notification icon image
import tel_icon from '../Images/telephone_icon.png'
import mail_icon from '../Images/email.png'

import contacts from "../Images/book.png"
export default function Contact() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await DataStore.query(USER);
      console.log(result)
      const modifiedFiles = result.map((item) => ({
        name: item.name,
        phone: item.phone,
        email:item.email,
      }));
      setFiles(modifiedFiles);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg = () => {
    navigate("/admin_aries_app/Msg")
  };

  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect"; 
    const body = ""; 
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  const handleDownload = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      console.log("signed URL: ", getUrlResult);
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      text: {
        primary: "#000000",
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', alignItems: 'center', boxShadow: 1, borderRadius: 4, backgroundColor: '#a5c7ed', padding: '1rem', marginTop: '1rem', marginBottom: '1rem', }}>
          <Button startIcon={<ArrowBackIcon />} onClick={backclick} sx={{ marginBottom: '0rem', color:'black' }}>
            Back
          </Button>
          <img src={contacts} alt="PDF Icon" style={{ width: "8%", marginRight:"10px" }} />
          <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
            Contact
          </Typography>
        </Box>

        <Box sx={{ padding: '1rem' }}>
          <input
            type="text"
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearch}
            style={{ width: '100%', padding: '0.5rem', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '1rem' }}
          />
        </Box>

        <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
          {loading ? (
            Array.from({ length: 12 }, (_, index) => (
              <Skeleton key={index} variant="text" width="100%" height={60} animation="wave" />
            ))
          ) : (
            filteredFiles
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort the files array by name
            .map((file, index) => (
              <List key={index}>
                <ListItem
                  style={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: '10px',
                    width: '100%'
                  }}
                >   
                  <ListItemAvatar>
                    <Avatar>
                      <img src={profile} alt="PDF Icon" style={{ width: "100%" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <ListItemText
                      primary={file.name}
                      secondary={file.phone}
                      style={{ fontWeight: '900', fontSize: '14px' }}
                    />
                    <a href={`tel:${file.phone}`}>
                      <img src={tel_icon} alt="Phone Icon" style={{ width: "28px", height: "28px" }} />
                    </a>
                    <a href={`mailto:${file.email}`}>
                      <img src={mail_icon} alt="Mail Icon" style={{marginLeft: '6px', width: "30px", height: "30px" }} />
                    </a>
                  </div>
                </ListItem>
              </List>
            ))
          )}
        </Box>
      </Container>
      <MobileBottomNavBar
        handleLogout={handleLogout}
        handleUserInfo={handleUserInfo}
        handleMsg={handleMsg}
        handleHelp={handleHelp}
        handleHome={handleHome}
      />
    </ThemeProvider>
  );
}
