import React, { useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  HomeOutlined,
  NotificationsOutlined,
  HelpOutline,
  PersonOutlined,
  ExitToAppOutlined,
} from '@mui/icons-material';
import { Mail, PictureAsPdf } from '@mui/icons-material';
import { list, getUrl } from "aws-amplify/storage";

import TouchAppIcon from '@mui/icons-material/TouchApp';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const StyledBottomNavigation = styled(BottomNavigation)({
  backgroundColor: '#007bff', // Blue background color
});

const StyledBottomNavigationAction = styled(BottomNavigationAction)({
  color: '#fff', // White font and icon color
  '&.Mui-selected': {
    color: '#fff', // White color for the selected option
  },
});

const handleHelps = () => {
  const email = 'syserp@aries.res.in';
  const subject = 'Need help With Aries Connect';
  const body = '';
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  window.open(mailtoLink);
};

const MobileBottomNavBar = ({
  handleHome,
  handleMsg,
  handleLogout,
  // handleUserInfo,
}) => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog box

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        handleHome();
        break;
      case 1:
        setAnchorEl(event.currentTarget);
        break;
      case 2:
        handleMsg();
        break;
      case 3:
        setOpenDialog(true); // Open dialog box for help
        break;
      case 4:
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadGuide = async () => {
    // Handle downloading guide
    try {
      const getUrlResult = await getUrl({
        key: "UserManual/userManual.pdf",
        options: {
          accessLevel: "guest",
        },
      });
      console.log("signed URL: ", getUrlResult);
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  const handleWriteMail = () => {
    handleHelps(); // Open mail client
  };

  return (
    <>
      <StyledBottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        sx={{ width: '100%', position: 'fixed', bottom: 0 }}
      >
        <StyledBottomNavigationAction label="Home" icon={<HomeOutlined />} />
       
        <StyledBottomNavigationAction label="Social" icon={<TouchAppIcon />} />
        <StyledBottomNavigationAction label="Notifications" icon={<NotificationsOutlined />} />
        <StyledBottomNavigationAction label="Help" icon={<HelpOutline />} />
        <StyledBottomNavigationAction label="Logout" icon={<ExitToAppOutlined />} />
      </StyledBottomNavigation>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem
          component="a"
          href="https://twitter.com/ARIESNainital"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          <XIcon sx={{ mr: 1 }} />
          Twitter
        </MenuItem>
        <MenuItem
          component="a"
          href="https://www.facebook.com/aries.nainital263002/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          <FacebookIcon sx={{ mr: 1 }} />
          Facebook
        </MenuItem>
        <MenuItem
          component="a"
          href="https://www.youtube.com/c/AriesNainitalUttarakhand"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          <YouTubeIcon sx={{ mr: 1 }} />
          YouTube
        </MenuItem>
      </Menu>

      {/* Dialog Box */}
      <div style={{ display: openDialog ? 'block' : 'none', position: 'fixed', top: '0',   left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '999' }}>
      <div style={{ backgroundColor: '#fff', borderRadius: '8px', width: '300px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', margin: 'auto', marginTop: '10%', position: 'relative' }}>
        <h2>Asking for help?</h2>
        <button style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }} onClick={() => setOpenDialog(false)}>×</button>
        <div style={{ marginBottom: '20px' }}>
          <span onClick={handleDownloadGuide} style={{ cursor: 'pointer', marginRight: '10px' }}><PictureAsPdf size={24} /> Download Guide</span> <br></br>
          <span onClick={handleWriteMail} style={{ cursor: 'pointer' }}><Mail size={24} /> Write Us a Mail</span>
        </div>
        <span onClick={() => setOpenDialog(false)} style={{ cursor: 'pointer', backgroundColor: '#f44336', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', position: 'absolute', bottom: '20px', right: '20px' }}>Cancel</span>
      </div>
    </div>
    </>
  );
};

export default MobileBottomNavBar;
