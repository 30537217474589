import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { list, getUrl } from "aws-amplify/storage";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function OfficeOrders() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: "order/OfficeOrder",
      });

      const modifiedFiles = result.items.map((file) => {
        let fileNameWithoutPrefix = file.key.replace("order/OfficeOrder/", "");
        let additionalInfo = "";
        const indexOfWef = fileNameWithoutPrefix.indexOf("(w.e.f.");
        if (indexOfWef !== -1) {
          additionalInfo = fileNameWithoutPrefix.substring(indexOfWef,fileNameWithoutPrefix.length-4);
          fileNameWithoutPrefix = fileNameWithoutPrefix.substring(0, indexOfWef);
        }
        const indexOfClosingBracket = fileNameWithoutPrefix.indexOf(")");
        if (indexOfClosingBracket !== -1) {
          fileNameWithoutPrefix = fileNameWithoutPrefix
            .substring(indexOfClosingBracket + 1)
            .trim();
        }
        return { ...file, name: fileNameWithoutPrefix, additionalInfo };
      });

      modifiedFiles.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
      setFiles(modifiedFiles);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg = () => {
    navigate("/admin_aries_app/Msg")
  };

  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect"; 
    const body = ""; 
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };
     
  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  const handleDownload = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      console.log("signed URL: ", getUrlResult);
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      text: {
        primary: "#000000",
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', alignItems: 'center', boxShadow: 1, borderRadius: 4, backgroundColor: '#a5c7ed', padding: '1rem', marginTop: '1rem', marginBottom: '1rem', }}>
          <Button startIcon={<ArrowBackIcon />} onClick={backclick} sx={{ marginBottom: '0rem', color:'black' }}>
            Back
          </Button>
          <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
            Office Order
          </Typography>
        </Box>

        <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />
              <Skeleton variant="text" width="100%" height={60} animation="wave" />

            </>
          ) : (
            files.map((file, index) => (
              <List key={index}>
                <ListItem
                  style={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: '10px',
                    width: '100%'
                  }}
                  secondaryAction={
                    <CloudDownloadIcon onClick={() => handleDownload(file.key)} />
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <PictureAsPdfIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    secondary={file.additionalInfo}
                    style={{ fontWeight: '900', fontSize: '14px' }}
                  />
                </ListItem>
              </List>
            ))
          )}
        </Box>
      </Container>
      <MobileBottomNavBar
        handleLogout={handleLogout}
        handleUserInfo={handleUserInfo}
        handleMsg={handleMsg}
        handleHelp={handleHelp}
        handleHome={handleHome}
      />
    </ThemeProvider>
  );
}
