import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import uploadImage from '../Images/upload_file.png';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

import { list, getUrl, uploadData } from "aws-amplify/storage";
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import { signOut } from "aws-amplify/auth";
import forms from '../Images/life.png'
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider,createTheme } from '@mui/material/styles';
export default function LifeCertificate() {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const { detailSend } = location.state || {};
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black
      },
      text: {
        primary: "#000000", // Black
      },
    },
  });

  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: `live/name=${detailSend.name} pan=${detailSend.pan}`,
      });
      // console.log('Result:', result); // Log the entire result object
      
      setFiles(result.items); // Store files directly without modification

    } catch (error) {
      console.log(error);
    }
  };
  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg=async()=>
    {
      navigate("/admin_aries_app/Msg")
    }   

    const handleHelp = () => {
      const email = "syserp@aries.res.in";
      const subject = "Need help With Aries Connect"; 
      const body = ""; 
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    };
     
const handleLogout = async () => {
try {
  await signOut();
  navigate("/admin_aries_app/login");
} catch (e) {
  console.log(e);
}
};


  const handleUploadButton = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file01 = event.target.files[0];
    console.log(file01);

    try {
      console.log(detailSend);
      const result = await uploadData({
        key: `live/name=${detailSend.name} pan=${detailSend.pan}/${file01.name}`,
        data: file01,
        options: {
          accessLevel: 'guest',
        }
      }).result;
      console.log('Succeeded: ', result);
    } catch (error) {
      console.log('Error : ', error);
    }
  };

  const handleDownload = (key) => {
    // Implement your handleDownload function here
    console.log('Downloading file:', key);
  };

  // Function to extract name after second '/'
  const extractName = (key) => {
    const parts = key.split('/');
    return parts.slice(2).join('/');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="md">
    <div>


    <Box

          
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  borderRadius: 4,
  padding: '1rem',
  backgroundColor: '#a5c7ed',

  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
          sx={{ marginBottom: '0rem',  color: 'black',
        }}
        >
          Back
        </Button>
<img
  src={forms} 
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Life Certificate

</Typography>
</Box>

      {/* <ArrowBackIcon fontSize="large" onClick={() => window.history.back()} style={{ position: "absolute", top: "20px", left: "10px", cursor: "pointer" }} />
     
      <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", fontStyle: "italic", marginTop: "10px" }}
        >
          <Diversity1Icon
            style={{
              height: "28px",
              width: "28px",
              marginBottom: "0",
              marginTop: "10",
            }}
          />
          Life Certificate
        </Typography>
 */}


        <ButtonComponent
        handleClick={handleUploadButton}
        image={uploadImage}
        text="Upload"
        fileInputRef={fileInputRef}
        onChange={handleFileChange}
      />
      {files.map((file, index) => (
        <List
          key={index}
          dense
          style={{ borderRadius: "10px", width: "100%", marginTop: "10px" }}
        >
          <ListItem
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
              borderRadius: "10px", // Add border radius
              width: "100%", // Increase width
            }}
            secondaryAction={
              <CloudDownloadIcon onClick={() => handleDownload(file.key)} />
            }
          >
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={extractName(file.key)} />
          </ListItem>
        </List>
      ))}
    </div>
    </Container>
     <MobileBottomNavBar

     handleLogout={handleLogout}
     handleUserInfo={handleUserInfo}
     handleMsg={handleMsg}
     handleHelp={handleHelp}
     handleHome={handleHome}
   
   />
 </ThemeProvider>
  );
}

// Button Component
const ButtonComponent = ({ handleClick, image, text, fileInputRef, onChange }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onChange}
        style={{ display: 'none' }}
      />
      <Button onClick={handleClick} variant="contained" style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={image} alt={text} style={{ width: '100px' }} />
          <Typography component="h1" variant="h6" style={{ color: 'black', fontWeight: '700' }}>
            {text}
          </Typography>
        </div>
      </Button>
    </div>

  );
};
