import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
// import { generateClient } from "aws-amplify/api";
// import { createUSER } from './graphql/mutations';
import { DataStore } from 'aws-amplify/datastore';
import {USER} from '../models';


// const client = generateClient()

const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    username: "",
    phonenumber:"",
    designation: "",
    userdivision: "",
    usergroup:"",
    employeeCode: "", 
    extensionNumber: "", 
    panNumber: "", 
    serviceType: "active",
    role: "regular"
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };


  const handleSubmit = async () => {
    if (!location.state || !location.state.email || !location.state.userId) {
      console.error("location.state.email or location.state.userId is undefined");
      return;
    }
    const useremail = location.state.email;
    const userid = location.state.userId;
    const userPanNumber = formData.panNumber;

    console.log("Email:", location.state.email);
    console.log("UserID:", location.state.userId);
   
    try {


      const userCopy = {
        query: USER,
        variables: {
          input: {
            email: useremail,
            name: formData.username,
            phone: "+91" + formData.phonenumber,
            designation: formData.usergroup,
            active: true,
            division: formData.userdivision, 
            admin: true,
            galleryAdmin: true, 
            noticeAdmin: true, 
            pan: formData.panNumber,
            uid: userid,
            userGroup: formData.designation,
          }
        }
      };
      
      await DataStore.save(userCopy);
      


      // const newUSER = await client.graphql({
      //   query: USER,
      //   variables: {
      //     input: {
      //       email: useremail,
      //       name: formData.username,
      //       phone:"+91"+formData.phonenumber,
      //       designation: formData.designation,
      //       active: true,
      //       devision: formData.userdivision,
      //       admin: true,
      //       GalleryAdmin: true,
      //       NoticeAdmin: true,
      //       pan: formData.panNumber,
      //       uid: userid,
      //       userGroup: formData.usergroup,
      //     }
      //   }
      // });
      console.log(userCopy);
      navigate('/admin_aries_app/',{state: {userid}});
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
  

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" style={{ fontWeight: 900 }}>
Please Update Your Profile
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="Name"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="phonenumber"
                    label="Phone Number"
                    name="phonenumber"
                    value={formData.phonenumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="designation"
                    label="Designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="userdivision"
                    label="Division"
                    name="userdivision"
                    value={formData.userdivision}
                    onChange={handleInputChange}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="usergroup"
                    label="usergroup"
                    name="usergroup"
                    value={formData.usergroup}
                    onChange={handleInputChange}
                  /> */}
                {/* </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="employeeCode"
                    label="Employee Code"
                    name="employeeCode"
                    value={formData.employeeCode}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="extensionNumber"
                    label="Extension Number"
                    name="extensionNumber"
                    value={formData.extensionNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="panNumber"
                    label="PAN Number"
                    name="panNumber"
                    value={formData.panNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="h1"
                    variant="h6"
                    style={{ fontWeight: 500 }}
                  >
                    Service Type
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="active"
                    name="serviceType"
                    value={formData.serviceType}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="active"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="retired"
                      control={<Radio />}
                      label="Retired"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="h1"
                    variant="h6"
                    style={{ fontWeight: 500 }}
                  >
                    Role
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="regular"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="regular"
                      control={<Radio />}
                      label="Regular"
                    />
                    <FormControlLabel
                      value="contract"
                      control={<Radio />}
                      label="Contract"
                    />
                    <FormControlLabel
                      value="retired"
                      control={<Radio />}
                      label="Retired"
                    />
                    <FormControlLabel
                      value="student"
                      control={<Radio />}
                      label="Student"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,background:"black"}}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
