import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { list, getUrl } from "aws-amplify/storage";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import List from "@mui/material/List";
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider,createTheme } from '@mui/material/styles';
import Button from "@mui/material/Button"
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import profile from "../Images/5739115-200.png";
import govt from "../Images/govt.png";
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";

import { useNavigate } from "react-router-dom";
 
export default function Order() {
  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black
      },
      text: {
        primary: "#000000", // Black
      },
    },
  });

  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: "order/Govt",
      });

      const modifiedFiles = result.items.map((file, index) => {
        let fileNameWithoutPrefix = file.key.replace("order/Govt/", "");
        let additionalInfo = "";
        const indexOfOrderOF = fileNameWithoutPrefix.indexOf("(Order Ref");
        if (indexOfOrderOF !== -1) {
          additionalInfo = fileNameWithoutPrefix.substring(
            indexOfOrderOF,
            fileNameWithoutPrefix.length - 4
          );
          fileNameWithoutPrefix = fileNameWithoutPrefix.substring(
            0,
            indexOfOrderOF
          );
        }

        return {
          ...file,
          name: fileNameWithoutPrefix,
          additionalInfo,
          serial: index + 1,
        };
      });

      sortFilesByLastModified(modifiedFiles); // Call sorting function before setting state
      setFiles(modifiedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const sortFilesByLastModified = (files) => {
    files.sort((a, b) => {
      if (b.lastModified != null && a.lastModified != null) {
        return b.lastModified.getTime() - a.lastModified.getTime();
      } else {
        return 0;
      }
    });
  };
  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  const handleDownload = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg=async()=>
    {
      navigate("/admin_aries_app/Msg")
    }   

    const handleHelp = () => {
      const email = "syserp@aries.res.in";
      const subject = "Need help With Aries Connect"; 
      const body = ""; 
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    };
     
const handleLogout = async () => {
try {
  await signOut();
  navigate("/admin_aries_app/login");
} catch (e) {
  console.log(e);
}
};


  return (

    <div
    style={{
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "top",
      justifyContent: "top",
    }}
  >
    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="md">
    {/* <Grid container justifyContent="center" style={{ marginTop: "20px" }}> */}

      {/* <Box
        sx={{
          position: 'absolute',
          top: '20px',
          left: '10px',
          cursor: 'pointer',
          color: theme.palette.primary.main,
        }}
        onClick={() => window.history.back()}
      >
        <ArrowBackIcon fontSize="large" />
      </Box>

      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontStyle: 'italic',
          fontFamily: 'Roboto, sans-serif',
          marginBottom: '20px',
          color: theme.palette.primary.main,
        }}
      >
        <AccountBalanceIcon fontSize="medium" sx={{ mr: 1 }} />
        Government Orders
      </Typography> */}

<Box

          
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  backgroundColor: '#a5c7ed',
  borderRadius: 4,
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
          sx={{ marginBottom: '0rem', color: 'black' }}
        >
          Back
        </Button>
<img
  src={govt} 
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Government Orders
</Typography>
</Box>




      <List sx={{ width: '100%' }}>
        {files.map((file, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              mb: isSmallScreen ? 2 : 3,
              padding: '10px 20px',
              backgroundColor: 'white',
            }}
            secondaryAction={
              <IconButton onClick={() => handleDownload(file.key)}>
                <CloudDownloadIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <PictureAsPdfIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography variant="subtitle1" sx={{fontWeight:'bold',  fontFamily: 'Roboto, sans-serif' }}>
                    {file.additionalInfo}
                  </Typography>
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      fontFamily: 'Roboto, sans-serif',
                      color: 'text.secondary',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                      width: 'calc(100% - 40px)', // Adjust width considering icon button width
                    }}
                  >
                    {file.name}
                  </Typography>
                </React.Fragment>
              }
            />
   
          </ListItem>
        ))}

      </List>

    {/* </Grid> */}
    </Container>
          <MobileBottomNavBar
  
          handleLogout={handleLogout}
          handleUserInfo={handleUserInfo}
          handleMsg={handleMsg}
          handleHelp={handleHelp}
          handleHome={handleHome}
        
        />
      </ThemeProvider>
      </div>
  );
}