import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { Container, CssBaseline, Typography, Box, IconButton, Button, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ArrowBack, ArrowForward, Download, GetApp } from '@mui/icons-material';
import { getUrl, list } from 'aws-amplify/storage';
import { createTheme } from '@mui/material/styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Black
    },
    text: {
      primary: '#000000', // Black
    },
  },
});

const GalleryDetails = () => {

  const { state } = useLocation();
  const { key, eventName } = state;
  const [imageUrls, setImageUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const imagesPerPage = 10; // Adjust as needed

  useEffect(() => {
    // console.log("")  
    fetchImageUrls();
  }, []);

  const fetchImageUrls = async () => {
    try {
      const result = await list({
        prefix: key,
      });
      // console.log(result.items);
      const urls = await Promise.all(
        result.items.map(async (item) => {
          const imageUrl = await getUrl({
            key: item.key,
            options: {
              accessLevel: 'guest',
            },
          });
          return {
            original: imageUrl.url.href,
            thumbnail: imageUrl.url.href, // You can use a different URL for thumbnails if available
            description: key.split('/').slice(1), // Description will be displayed under the image
          };
        })
      );
      // Remove the first element from the array
      urls.shift();
      setImageUrls(urls);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/admin_aries_app/gallery");
  };


  const download = (currentIndex) => {
    const imageUrl = imageUrls[currentIndex].original;
    window.open(imageUrl);
  };
  
  

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };



  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div
      style={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box>
            <IconButton onClick={handleBack} style={{ position: 'absolute', top: '20px', left: '20px' }}>
              <ArrowBack />  
            </IconButton>

           
      {/* <Button 
  onClick={() => download(currentIndex)}
  variant="contained"
  color="primary"
  startIcon={<GetApp />}
  style={{ left: '250px' }}
>
 
</Button> */}

            <Typography
              component="h1"
              variant="h3"
              style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px' }}
            >
              {eventName} {/* Display event name here */}
            </Typography>
            <ImageGallery items={imageUrls.slice(currentIndex, currentIndex + imagesPerPage)} startIndex={0} />
           
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default GalleryDetails;
