import React, { useEffect, useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Notify } from '../models'; // Assuming Notify is your model
import { useLocation } from 'react-router-dom';
import { Typography, Container, Grid, Avatar, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import notificationIcon from '../Images/man.png'; // Import your notification icon image
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { signOut } from "aws-amplify/auth";
import png from '../Images/pngn.png'

function MsgDetails() {
  const navigate = useNavigate();

  const location = useLocation();
  const { aman } = location.state || {};
  const [msg, setMessage] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    async function fetchMessage() {
      try {
        const models = await DataStore.query(Notify);
        
        for (let i = 0; i < models.length; i++) {
          if (models[i].id === aman) {
            setMessage(models[i]);
            setLoading(false);
            break; 
          }
        }
      } catch (error) {
        console.error('Error fetching message:', error);
        setLoading(false); 
      }
    }

    fetchMessage();
  }, [aman]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd-MM-yyyy');
  };
  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg=async()=>
    {
      navigate("/admin_aries_app/Msg")
    }   
     
const handleLogout = async () => {
try {
  await signOut();
  navigate("/admin_aries_app/");
} catch (e) {
  console.log(e);
}
};
const handleHelp = () => {
const email = "syserp@aries.res.in";
const subject = "Need help With Aries Connect"; 
const body = ""; 
const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
window.open(mailtoLink);
};

const backclick = () => {
  navigate("/admin_aries_app/Home");
};

  return (
    <>
    <Box

          
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  borderRadius: 4,
  backgroundColor: '#a5c7ed',

  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
         // Assuming this function handles navigation back
          sx={{ marginBottom: '0rem',  color: 'black',
        }}
        >
          Back
        </Button>
<img
  src={png} // Replace with your profile update logo path
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Notification
</Typography>
</Box>



      <Container>
        {loading ? ( 
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          // If loading is false, display the notification details
          <>
                <List>
                  <ListItem>
                    <img src={notificationIcon} alt="Notification Icon" style={{ marginRight: 10, width: 50 }} />
                    <ListItemText
                      primary="Admin"
                      secondary={formatDate(msg?.createdAt)}
                      sx={{fontWeight: 'bold'}}
                    />
                  </ListItem>
                </List>
            <Typography variant="h4">{msg?.message}</Typography>
         <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>-Admin ARIES</Typography>

          </>
        )}
      </Container>

         <MobileBottomNavBar
  
  handleLogout={handleLogout}
  handleUserInfo={handleUserInfo}
  handleMsg={handleMsg}
  handleHelp={handleHelp}
  handleHome={handleHome}

/>
      </>
  );
}

export default MsgDetails;
