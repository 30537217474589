import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import CampaignIcon from '@mui/icons-material/Campaign';
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TaskIcon from '@mui/icons-material/Task';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { list, getUrl } from "aws-amplify/storage";
import Grid from "@mui/material/Grid";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import notice from "../Images/notice.png"
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider,createTheme } from '@mui/material/styles';

export default function Order() {
  const [files, setFiles] = useState([]);
  const [selectedFileContent, setSelectedFileContent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black
      },
      text: {
        primary: "#000000", // Black
      },
    },
  });

  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: "order/notice",
      });

      const modifiedFiles = [];

      for (const file of result.items) {
        const getUrlResult = await getUrl({
          key: file.key,
          options: {
            accessLevel: "guest",
          },
        });

        const response = await fetch(getUrlResult.url.href);
        const json = await response.json();

        modifiedFiles.push({ ...file, name: json.title });
      }

      sortFilesByLastModified(modifiedFiles); 
      setFiles(modifiedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const sortFilesByLastModified = (files) => {
    files.sort((a, b) => {
      if (b.lastModified != null && a.lastModified != null) {
        return new Date(b.lastModified) - new Date(a.lastModified);
      } else {
        return 0;
      }
    });
  };

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };


  const handleView = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });

      const response = await fetch(getUrlResult.url.href);
      const json = await response.json();

      setSelectedFileContent(json);
      setOpenDialog(true);
    } catch (error) {
      console.error("Error getting JSON file:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFileContent(null);
  };
  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg=async()=>
    {
      navigate("/admin_aries_app/Msg")
    }   

    const handleHelp = () => {
      const email = "syserp@aries.res.in";
      const subject = "Need help With Aries Connect"; 
      const body = ""; 
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    };
     
const handleLogout = async () => {
try {
  await signOut();
  navigate("/admin_aries_app/login");
} catch (e) {
  console.log(e);
}
};

  return (
    <div
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "top",
      }}
    >

    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="md">
    {/* <Grid container justifyContent="center" style={{ marginTop: "20px" }}> */}

<Box

          
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  borderRadius: 4,
  backgroundColor: '#a5c7ed',

  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
         // Assuming this function handles navigation back
          sx={{ marginBottom: '0rem',  color: 'black',
        }}
        >
          Back
        </Button>
<img
  src={notice} // Replace with your profile update logo path
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Notice
</Typography>
</Box>


<Box sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }}>

    {/* <ArrowBackIcon fontSize="large" onClick={() => window.history.back()} style={{ position: "absolute", top: "20px", left: "10px", cursor: "pointer" }} /> */}
      <Grid item xs={12} md={8}>
{/*         
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", fontStyle: "italic" }}
      >
          <TaskIcon style={{ height: "28px", width: "28px", marginBottom: "0", marginTop: "10" }} /> Notice
        </Typography> */}
        {files.map((file, index) => (
          <List key={index} dense style={{ borderRadius: '10px', width: '100%', marginTop: '10px' }}>
            <ListItem
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
                borderRadius: '10px', // Add border radius
                width: '100%' // Increase width
              }}
              secondaryAction={
                <RemoveRedEyeIcon onClick={() => handleView(file.key)} />
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <CampaignIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={file.name}
              />
            </ListItem>
          </List>
        ))}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedFileContent && `Title: ${selectedFileContent.title}`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {selectedFileContent && `Description: ${selectedFileContent.description}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    {/* </Grid> */}
    </Box>
     </Container>
     <MobileBottomNavBar

     handleLogout={handleLogout}
     handleUserInfo={handleUserInfo}
     handleMsg={handleMsg}
     handleHelp={handleHelp}
     handleHome={handleHome}
   
   />
 </ThemeProvider>
 
 </div>
  );
}
