import React, { useEffect, useState } from "react";
import { list, getUrl } from "aws-amplify/storage";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import nps from "../Images/nps.png";

import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { Container, CssBaseline, Skeleton } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function Order() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black
      },
      text: {
        primary: "#000000", // Black
      },
    },
  });

  useEffect(() => {
    onChange();
  }, []);

  const onChange = async () => {
    try {
      const result = await list({
        prefix: "order/nps",
      });

      const modifiedFiles = result.items.map((file) => {
        let fileNameWithoutPrefix = file.key.replace("order/nps/", "");
        let additionalInfo = "";
        const indexOfWef = fileNameWithoutPrefix.indexOf(
          "-(w.e.f.-05.04.2024)"
        );
        if (indexOfWef !== -1) {
          additionalInfo = "(w.e.f.-05.04.2024)";
          fileNameWithoutPrefix = fileNameWithoutPrefix.substring(
            0,
            indexOfWef
          );
        }
        return { ...file, name: fileNameWithoutPrefix, additionalInfo };
      });

      sortFilesByLastModified(modifiedFiles); 
      setFiles(modifiedFiles);
      setLoading(false); // Set loading to false after data fetch
    } catch (error) {
      console.log(error);
    }
  };

  const sortFilesByLastModified = (files) => {
    files.sort((a, b) => {
      if (b.lastModified != null && a.lastModified != null) {
        return b.lastModified.getTime() - a.lastModified.getTime();
      } else {
        return 0;
      }
    });
  };

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg = async () => {
    navigate("/admin_aries_app/Msg")
  };

  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect"; 
    const body = ""; 
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = async (filename) => {
    try {
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      console.log("signed URL: ", getUrlResult);
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            boxShadow: 1,
            borderRadius: 4,
            backgroundColor: '#a5c7ed',
            padding: '1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={backclick}
            sx={{ marginBottom: '0rem', color: 'black'  }}
          >
            Back
          </Button>
          <img
            src={nps} 
            alt="Profile Update Logo"
            style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
          />
          <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
            NPS Updates
          </Typography>
        </Box >

        <Box sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }}>
          {loading ? ( // Rendering skeleton components while loading
            Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} animation="wave" variant="rectangular" height={64} sx={{ marginBottom: 1 }} />
            ))
          ) : (
            <List dense>
              {files.map((file, index) => (
                <ListItem
                  key={index}
                  sx={{
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    mb: 1,
                    backgroundColor: 'white',
                  }}
                  secondaryAction={
                    <IconButton onClick={() => handleDownload(file.key)}>
                      <CloudDownloadIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 40px)', // Adjust width considering icon button width
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {file.name}
                      </Typography>
                    }
                    secondary={file.additionalInfo}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <MobileBottomNavBar
          handleLogout={handleLogout}
          handleUserInfo={handleUserInfo}
          handleMsg={handleMsg}
          handleHelp={handleHelp}
          handleHome={handleHome}
        />
      </Container>
    </ThemeProvider>
  );
};
