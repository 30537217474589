import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";

import { getCurrentUser } from "aws-amplify/auth";
import { TextField, Button, CircularProgress, Typography, Container, Grid } from "@mui/material";
import { DataStore } from 'aws-amplify/datastore';
import { USER } from '../models';
import MobileBottomNavBar from "../Auth/mobilenavbar";
import { signOut } from "aws-amplify/auth";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import profile from '../Images/5739115-200.png'
import user from '../Images/User-Profile.png'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


import { CssBaseline } from '@mui/material';
import { ThemeProvider,createTheme } from '@mui/material/styles';

// import { generateClient } from 'aws-amplify/api';

const client = generateClient();
const UserInfo = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const defaultTheme = createTheme({
        palette: {
          primary: {
            main: "#000000", // Black
          },
          text: {
            primary: "#000000", // Black
          },
        },
      });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const { userId } = await getCurrentUser();
            const response =  await DataStore.query(USER);
            
            const currentUser=0;
            
for(var i=0;i<response.length;i++){
    // console.log(response[i].id)
    // console.log(userId,',**********')
    if(response[i].uid==userId){
       
            setUserDetails(response[i]);
            console.log(userDetails);
       
    }
}
          

            setLoading(false);
        } catch (error) {
            console.log("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUserInfo = () => {
        navigate('/admin_aries_app/UserInfo')
      };

      const handleHome = () => {
        navigate('/admin_aries_app/Home')
      };

      const handleMsg=async()=>
        {
          navigate("/admin_aries_app/Msg")
        }   
         
   const handleLogout = async () => {
    try {
      await signOut();
      navigate("/admin_aries_app/login");
    } catch (e) {
      console.log(e);
    }
  };
  const handleHelp = () => {
    const email = "syserp@aries.res.in";
    const subject = "Need help With Aries Connect"; 
    const body = ""; 
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };
    


  const handleSave = async (e) => {
    e.preventDefault();
    setOpenConfirmation(true);
};


    const handleSaveConfirmed  = async (e) => {
        e.preventDefault();
        handleOpenConfirmation();

        try {
            
            
            const { userId } = await getCurrentUser();
            const response =  await DataStore.query(USER);
            
            // const currentUser=0;
            var model;
for(var i=0;i<response.length;i++){
    // console.log(response[i].id)
    // console.log(userId,',**********')
    if(response[i].uid===userId){
       model=response[i];
          
       
    }
}
            await DataStore.save(USER.copyOf(model, item => {
               item.email=userDetails.email;
               item.phone=userDetails.phone;
               item.name=userDetails.name;
               item.designation=userDetails.designation;
               item.division=userDetails.division;
}));
setOpenConfirmation(false);
// setSuccessMessage("User details saved successfully.");
navigate('/admin_aries_app/Home');
        }


        catch (error) {
            console.error("Error updating user:", error);
            // Handle error gracefully (e.g., show error message)
        }
    };
    
    

const handleOpenConfirmation = () => {
  setOpenConfirmation(true);
};

const handleCloseConfirmation = () => {
  setOpenConfirmation(false);
};



// const handleSave = async (e) => {
//     e.preventDefault();
//     const isConfirmed = window.confirm("Are you sure you want to save these details?");
//     if (isConfirmed) {
//       try {
            
            
//         const { userId } = await getCurrentUser();
//         const response =  await DataStore.query(USER);
        
//         // const currentUser=0;
//         var model;
// for(var i=0;i<response.length;i++){
// // console.log(response[i].id)
// // console.log(userId,',**********')
// if(response[i].uid===userId){
//    model=response[i];
      
   
// }
// }
//         await DataStore.save(USER.copyOf(model, item => {
//            item.email=userDetails.email;
//            item.phone=userDetails.phone;
//            item.name=userDetails.name;
//            item.designation=userDetails.designation;
//            item.division=userDetails.division;
// }));

// navigate('/admin_aries_app/Home');
//     } catch (error) {
//             console.error("Error updating user:", error);
//             // Handle error gracefully (e.g., show error message)
//         }
//     }
// };















    const backclick = () => {
        navigate("/admin_aries_app/Home");
      };

  
     

    const handleChangePassword = () => {
        navigate("/admin_aries_app/ForgetPassword");
    };


    if (loading) {
        return (
            <Container maxWidth="xs">
                <CircularProgress />
            </Container>
        );
    }

    return (




        <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md">
          
          <Box

          
  sx={{
    display: 'flex',
    alignItems: 'center',
    boxShadow: 1,
    backgroundColor: '#a5c7ed',

    borderRadius: 4,
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  }}
>
<Button
            startIcon={<ArrowBackIcon />}
            onClick={backclick} // Assuming this function handles navigation back
            sx={{ marginBottom: '0rem' }}
          >
            Back
          </Button>
  <img
    src={profile} // Replace with your profile update logo path
    alt="Profile Update Logo"
    style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
  />
  <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
    Update Profile Details
  </Typography>
</Box>

          
<form>
  <div style={{ textAlign: 'center' }}>
    <img
      src={user} // Replace with your profile update logo path
      alt="Profile Update Logo"
      style={{
        width: '121px', // Adjust size as needed
        height: '95px', // Make sure height matches width for a perfect circle
        borderRadius: '50%', // This makes the image rounded
        border: '2px solid #fff', // Optional: adds a border to the image
        marginBottom: '0.5rem', // Spacing between image and username
      }}
    />
    <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
      {userDetails.name} {/* Displaying username */}
    </Typography>
    <Typography variant="body2" sx={{ color: '#757575', marginBottom: '1rem' }}>
      Employee ID: {userDetails.EmpID} {/* Displaying employee ID */}
    </Typography>






  </div>





  <Grid container spacing={2}>
  {userDetails &&
    ['email', 'phone', 'name', 'division'].map(key => (
      <Grid item xs={12} key={key}>
        {key !== 'email' ? (
          <TextField
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            name={key}
            value={userDetails[key] || ''}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
          />
        ) : (
          <TextField
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            name={key}
            value={userDetails[key] || ''}
            fullWidth
            variant="outlined"
            disabled // This line disables the email field
          />
        )}
      </Grid>
    ))}
</Grid>

  <Button
    type="submit"
    variant="contained"
    color="primary"
    fullWidth
    style={{ marginTop: '1rem' }}
    onClick={handleSave}
  >
    Save
  </Button>
</form>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: '1rem' }}
            onClick={handleChangePassword}
          >
            Change Password
          </Button>
          
          </Container>
     <MobileBottomNavBar

     handleLogout={handleLogout}
     handleUserInfo={handleUserInfo}
     handleMsg={handleMsg}
     handleHelp={handleHelp}
     handleHome={handleHome}
   
   />

<Dialog
    open={openConfirmation}
    onClose={handleCloseConfirmation}
>
    <DialogTitle>Confirm Save</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Are you sure you want to save these details?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseConfirmation} color="primary">
            Cancel
        </Button>
        <Button onClick={handleSaveConfirmed} color="primary">
            Save
        </Button>
    </DialogActions>
</Dialog>

{successMessage && (
    <Typography variant="body1" sx={{ color: 'green' }}>
        {successMessage}
    </Typography>
)}



 </ThemeProvider>
      );
    };
    
export default UserInfo;