import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmSignUp } from 'aws-amplify/auth';
// import ariesLogo from "../Images/aries_logo.png"; // Import your logo image
import backgroundImage from "../Images/backgroundImage03.jpg"; // Import your background image

const defaultTheme = createTheme();

export default function ConfirmOtp() {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    onetimepassword: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { onetimepassword } = formData;

    // Check if location.state exists before accessing its properties
    if (!location.state || !location.state.email || !location.state.userId) {
      console.error("location.state.email or location.state.userId is undefined");
      return;
    }

    const username = location.state.email;

    await handleSignUpConfirmation({ username, confirmationCode: onetimepassword });
  };

  async function handleSignUpConfirmation({ username, confirmationCode }) {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode
      });

      handleClick(location.state.email, location.state.userId);
      
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  const handleClick = (email, userId) => {
    // Perform any necessary action here
    navigate("/admin_aies_app/Additional_Information", { state: { email, userId } });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          backgroundColor: "rgb(245,245,245 )", // Smokey white
          minHeight: "100vh", 
          display: "flex",
          flexDirection: "column",
         justifyContent:"center",
          padding: "20px",
          textAlign: "center",
        }}
      >
        
            <Typography component="h1" variant="h4" style={{
              fontWeight: '600'
            }}>
              Confirm OTP Here
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: "100%" }}>
              <Typography  variant='h6' sx={{  mb: 2 }}>
                Please Enter OTP sent to your Email:
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                type='number'
                name='onetimepassword'
                id='onetimepassword'
                label='Pin'
                onChange={handleChange}
                InputProps={{
                  sx: {
                    fontWeight: "bold",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontWeight: "bold",
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1  ,background:'black'}}
              >
                Verify
              </Button>
            </Box>
          </Box>
        {/* </Container> */}
      {/* </Box> */}
    </ThemeProvider>
  );
}
