import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { updatePassword } from 'aws-amplify/auth';
import { useNavigate, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    username: location.state?.username || "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setErrors({ confirmPassword: "New password and confirm password do not match." });
      return;
    }
    await handleResetPassword(formData.oldPassword, formData.newPassword);
  };

  async function handleResetPassword(oldPassword, newPassword) {
    const toastStyle = {
      color: "white",
      backgroundColor: "red",
      fontWeight: "bold",
      fontSize: "16px",
    };
    const toastSuccess = {
      color: "white",
      backgroundColor: "green",
      fontWeight: "bold",
      fontSize: "16px",
    };

    try {
      await updatePassword({ oldPassword, newPassword });
      toast.success("Password updated successfully", { style: toastSuccess });
    } catch (error) {
      console.log(error);
      toast.error("Password not updated. Please check your credentials and try again", { style: toastStyle });
    }
  }

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };

  return (
    
    <Box
      sx={{
        backgroundColor: "rgb(245,245,245)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
          sx={{bottom:'150px',right:'135px',marginBottom: '0rem', color: 'black' }}
        >
          Back
        </Button>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
          Change Password
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please enter the old and the new passwords below
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            required
            fullWidth
            label="Old Password"
            type="password"
            id="oldPassword"
            name="oldPassword"
            autoComplete="current-password"
            onChange={handleChange}
            value={formData.oldPassword}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />

          <TextField
            required
            fullWidth
            label="New Password"
            type="password"
            id="newPassword"
            name="newPassword"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.newPassword}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />

          <TextField
            required
            fullWidth
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.confirmPassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 1, background: 'black' }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
}
