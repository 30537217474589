import React, { useState } from "react";
import {
    Typography,
    TextField,
    Button,
    Box,
} from "@mui/material";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { ToastContainer, toast } from "react-toastify";
import { sendUserAttributeVerificationCode } from 'aws-amplify/auth';

import "react-toastify/dist/ReactToastify.css";

export default function ForgetPassword() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    otp: "",
    confirmationCode: "", // Added for step 2
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  

  async function handleResetPassword(username) {
    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
    } catch (error) {
      console.log(error);
    }
  }
  
//   async function handleResetPassword(key) {
//     try {
//       await sendUserAttributeVerificationCode({
//         userAttributeKey: key
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }



  function handleResetPasswordNextSteps(output) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        setStep(2); // Move to step 2
        break;
      case 'DONE':
        console.log('Successfully reset password.');
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 1) {
     console.log(formData.email)
      await handleResetPassword(formData.email);
    } else if (step === 2) {
      // Step 2: Submit OTP and new password
      await handleConfirmResetPassword({
        username: formData.email,
        confirmationCode: formData.otp,
        confpswd:formData.cnf,
        newPassword: formData.newPassword,
      });
    }
  };




  async function handleConfirmResetPassword({ username, confirmationCode,confpswd, newPassword }) {

let pwd = await checkPassword(newPassword,confpswd );
if(pwd === 'true'){
    try {
        await confirmResetPassword({ username, confirmationCode, newPassword });
        toast.success("Password updated successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to reset password. Please try again.");
      }

}

else{
    return;
}

       
  }



  async function checkPassword(newPassword,confirmationCode)
{
    if(newPassword === confirmationCode){
        return 'true';
    }
    else{
        toast.error("Confirm Password Should be same.");
        return 'false';
    }

}

  return (
    <Box
      sx={{
        backgroundColor: "rgb(245,245,245)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        {step === 1 ? "Forgot Password" : "Reset Password"}
      </Typography>
      
      {step === 1 ? (
        // Step 1: Enter email
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            required
            fullWidth
            label="Email"
            type="email"
            id="email"
            name="email"
            autoComplete="email"
            onChange={handleChange}
            value={formData.email}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 1, background: 'black' }}
          >
            Next
          </Button>
        </Box>
      ) : (
        // Step 2: Enter OTP and new password
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

<TextField
            required
            fullWidth
            label="One Time Password(OTP)"
            type="text"
            id="otp"
            name="otp"
            autoComplete="otp"
            onChange={handleChange}
            value={formData.otp}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />


          

          <TextField
            required
            fullWidth
            label="New Password"
            type="password"
            id="newPassword"
            name="newPassword"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.newPassword}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />

<TextField
            required
            fullWidth
            label="Confirm Password"
            type="text"
            id="cnf"
            name="cnf"
            autoComplete="cnf"
            onChange={handleChange}
            value={formData.cnf}
            error={!!errors.cnf}
            helperText={errors.cnf}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{ style: { fontWeight: "bold" } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 1, background: 'black' }}
          >
            Reset Password
          </Button>
        </Box>
      )}

      <ToastContainer />
    </Box>
  );
}
