  import * as React from "react";
  import Button from "@mui/material/Button";
  import CssBaseline from "@mui/material/CssBaseline";
  import TextField from "@mui/material/TextField";
  import Box from '@mui/material/Box';
  // import FormControlLabel from "@mui/material/FormControlLabel";
  // import Checkbox from "@mui/material/Checkbox";
  import Link from "@mui/material/Link";
  import Grid from "@mui/material/Grid";
  import Typography from "@mui/material/Typography";
  // import Container from "@mui/material/Container";
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import { signIn } from "aws-amplify/auth";
  import { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { getCurrentUser ,signOut} from "aws-amplify/auth";
  import { Visibility, VisibilityOff } from "@mui/icons-material";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  // import backgroundImage01 from "../Images/backgroundImage03.jpg";
  import ariesLogo from '../Images/ARIES_LOGO_LOGIN.png';
  // import { icon } from "@fortawesome/fontawesome-svg-core";
  import { DataStore } from 'aws-amplify/datastore';
  // import { DataStore } from 'aws-amplify/datastore';
  import { USER } from '../models';
  import { AuditLog } from '../models';
  const defaultTheme = createTheme();

  export default function Login() {
    const navigate = useNavigate();

    useEffect(() => {
      fetchData();
    }, []);



    async function auditLog (){


      
      const { userId } = await getCurrentUser();
       
    //   var models = await DataStore.query(USER);

    //  if(models==null)
    //   {
    //  models = await DataStore.query(USER);
    //   }

    //   const allUSERS=models
    //   for (let i = 0; i < allUSERS.length; i++) {
    //     const detailSend = allUSERS[i];
        
    //     if (detailSend.uid === userId) {
          //localStorage.setItem('data', detailSend.name);
          await DataStore.save(
            new AuditLog({
              "username": userId,
              "email": formData.username,
              "loginTime": new Date().toLocaleString(), 
              // "name": detailSend.name,
            })
          );
          navigate("/admin_aries_app/Home");
       
      //  }
     // }

    }
    const fetchData = async () => {


      try {
        const token = localStorage.getItem('authToken');
      const expirationTime = localStorage.getItem('authTokenExpiration');

      const { userId } = await getCurrentUser();
   if(userId==null){
    console.log(userId)
     return;
   }
      if (!token || !expirationTime) {
        await signOut();
        console.log("token not found")
  
        return;
      }
        navigate('/admin_aries_app/Home');
        }
        
      
      catch (error) {
console.log(error)
return
      }
    };

    const [formData, setFormData] = useState({
      username: "",
      password: "",
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      await signInUser(formData.username, formData.password);
    };

    const togglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    function generateToken() {
      // Simple token generation for demonstration purposes
      return Math.random().toString(36).substr(2);
    }
  
    async function signInUser(username, password) {
      try {
        const result = await signIn({ username, password });
        console.log(result);
        const token = generateToken();
        const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds
        localStorage.setItem('authToken', token);
        localStorage.setItem('authTokenExpiration', expirationTime);
        auditLog();  
        navigate("/admin_aries_app/Home");
       
        return;    
      } catch (error) {
        toast.error("Username & Password is Incorrect!", { style: toastStyle });
        // handleSignInError(error);
        console.log("error signing in", error);
      }
    }
    const toastStyle = {
      color: "white",
      backgroundColor: "red",
      fontWeight: "bold",
      fontSize: "16px",
    };
    const errors = {}; // Define your errors object here
    return (
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Box sx={{
          backgroundColor: "rgb(245,245,245 )", // Smokey white

          display: "flex",

        }}
        >
          <Grid container justifyContent="flex-end" marginTop={3} marginRight={2}>
            <Grid item>
              <Link href="/admin_aries_app/faq" variant="body2" sx={{ color: "black" }}>
                Help?
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(245,245,245 )", // Smokey white
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <img
            src={ariesLogo}
            alt="Aries Logo"
            style={{
              width: "300px",
              marginBottom: "20px",
            }}
          />
          <Box
    component="form"
    onSubmit={handleSubmit}
    noValidate
    sx={{ mt: 1, width: "100%" }} // Set the maximum width for the form
  >
    <Typography
      // component="h6"
      // variant="h6"
      sx={{  textAlign: "left" , mt: 2, mb: 1, color: "#666"  }} // Adjusted fontWeight
    >
      Email
    </Typography>
    <TextField
      // margin="normal"
      required
      fullWidth
      id="email"
      name="username"
      // autoComplete="off"
      onChange={handleChange}
      value={formData.username}
    />
    <Grid container  alignItems="center">
      <Grid item xs>
        <Typography
          // component="h6"
          // variant="h6"
          sx={{ textAlign: "left" , mt: 2, mb: 1 , color: "#666" }} // Adjusted fontWeight
        >
          Password
        </Typography>
      </Grid>
      <Grid item>
        <Link href="/admin_aries_app/ResetPassword" variant="body2" sx={{ color: "black" }}>
          Forgot password?
        </Link>
      </Grid>
    </Grid>
    <TextField
    required
    fullWidth
    name="password"
    type={showPassword ? "text" : "password"}
    id="password"
    autoComplete="off"
    onChange={handleChange}
    value={formData.password}
    // sx={{ mt: 2, mb: 2 }} // Add margin top and bottom
    InputProps={{
      endAdornment: (
        <Button onClick={togglePasswordVisibility} size="small">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </Button>
      ),
    }}
  />

    <Button
      variant="contained"
      type="submit"
      fullWidth
      sx={{ mt: 3, mb: 5, backgroundColor: "BLACK" }} // Add color: "black" to make the button black
    >
      Log In
    </Button>
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, color: "grey", fontSize: "14px", marginBottom: "5px" }} // Adjusted fontWeight and variant
        >
          Need AriesConnect credentials? No problem, just
        </Typography>
        <Link href="/admin_aries_app/SignUp" variant="body2" sx={{ color: "black" }}>
          {" Register"}
        </Link>
      </Grid>
    </Grid>
  </Box>

        
          <ToastContainer position="bottom-center" />
        </Box>
      </ThemeProvider>
    );
  }
