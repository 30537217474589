import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import { list, getUrl } from "aws-amplify/storage";
import gallery from '../Images/gallery.png'
import { signOut } from "aws-amplify/auth";
import MobileBottomNavBar from "../Auth/mobilenavbar";


const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Black
    },
    text: {
      primary: "#000000", // Black
    },
  },
});

export function Gallery() {
  const [categories, setCategories] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const result = await list({ prefix: "Gallery/" });
      const filteredCategories = result.items.filter((item) => item.key.endsWith("/"));
      const categoriesWithDates = await Promise.all(
        filteredCategories.map(async (category) => {
          const { key, lastModified } = category;
          const imageUrl = await getUrl({
            key: key + 'cover.jpg',
            options: {
              accessLevel: "guest",
            },
          });
          return {
            key,
            lastModified,
            imageUrl: imageUrl.url.href,
          };
        })
      );
      setCategories(categoriesWithDates);
    } catch (error) {
      console.log(error);
    }
  };

  const backclick = () => {
    navigate("/admin_aries_app/Home");
  };


  const handleCategoryClick = (category) => {
    const { key } = category;
    navigate("/admin_aries_app/GalleryDetails", { state: { key } });
  };
  const handleUserInfo = () => {
    navigate('/admin_aries_app/UserInfo')
  };

  const handleHome = () => {
    navigate('/admin_aries_app/Home')
  };

  const handleMsg=async()=>
    {
      navigate("/admin_aries_app/Msg")
    }   

    const handleHelp = () => {
      const email = "syserp@aries.res.in";
      const subject = "Need help With Aries Connect"; 
      const body = ""; 
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    };
     
const handleLogout = async () => {
try {
  await signOut();
  navigate("/admin_aries_app/login");
} catch (e) {
  console.log(e);
}
};

  return (
    <div
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "top",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />


          <Box>
          <Box        
sx={{
  display: 'flex',
  alignItems: 'center',
  boxShadow: 1,
  borderRadius: 4,
  backgroundColor: '#a5c7ed',

  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
}}
>
<Button
          startIcon={<ArrowBackIcon />}
          onClick={backclick}
          sx={{ marginBottom: '0rem',color:'black' }}
        >
          Back
        </Button>
<img
  src={gallery} 
  alt="Profile Update Logo"
  style={{ width: '30px', marginRight: '0.5rem' }} // Adjust size and spacing as needed
/>
<Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
Gallery
</Typography>
</Box>
            {/* <Typography
              component="h6"
              variant="h4"
              sx={{
                color: "black",
                textAlign: "center",
                marginBottom: "20px",
                fontSize: { xs: "30px", md: "10px" },
                fontWeight: 900,
              }}
            >
              Gallery
            </Typography> */}

            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {categories.map((category, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    fontWeight: "bold",
                    margin: "10px",
                    width: "100px",
                    height: "140px",
                  }}
                  onClick={() => handleCategoryClick(category)}
                >
                  <Stack direction="column" spacing={0.1} alignItems="center">
                    <img
                      src={category.imageUrl}
                      alt="Category Cover"
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                    <Typography
                      component="h1"
                      variant="h4"
                      style={{ color: "#000000", fontSize: "10px",  textTransform: 'capitalize' , fontWeight: "bold", width: '80px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      title={category.key.split("/").slice(1)}
                    >

                {<span style={{ fontSize: "10px", textTransform: 'capitalize' }}> {category.key.split("/").slice(1)} </span>}
{/* 
                      {category.key.split("/").slice(1)} */}
                    </Typography>
                  

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: "10px" }}
                    >
                     ({new Date(category.lastModified).toLocaleDateString()})
                    </Typography>
                  </Stack>
                </Button>
              ))}
            </Box>
          </Box>
    
        </Container>
        <MobileBottomNavBar
  
  handleLogout={handleLogout}
  handleUserInfo={handleUserInfo}
  handleMsg={handleMsg}
  handleHelp={handleHelp}
  handleHome={handleHome}

/>
      </ThemeProvider>
    </div>
  );
}

export default Gallery;
